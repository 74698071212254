<div class="sidebar">
    <div class="nav-title">
        {{ navTitle }}
    </div>
    <div class="nav">
        <div class="nav-item" [ngClass]="{selected: selectedMenu == navItems['plan care']}"
            (click)="onMenuSelect(navItems['plan care'])">
            <div class="item-title">
                <span>
                    <img src="assets/images/plan-care.svg" alt="plan-care">
                </span>
                <span>
                    {{ 'SIDENAV.LABELS.Plan_care' | translate }}
                </span>
            </div>
            <div class="pointer" style="color: #A7A7A7">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </div>

        <!-- <div class="nav-item" [ngClass]="{selected: selectedMenu == navItems.medication}"
            (click)="onMenuSelect(navItems.medication);">
            <div class="item-title">
                <span>
                    <img src="assets/images/medication.svg" alt="medication">
                </span>
                <span>
                    {{ 'SIDENAV.LABELS.Medication' | translate }}
                </span>
            </div>
            <div class="pointer" style="color: #A7A7A7">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </div> -->

        <!-- <div class="nav-item" [ngClass]="{selected: selectedMenu == navItems['vital signs']}"
            (click)="onMenuSelect(navItems['vital signs']);">
            <div class="item-title">
                <span>
                    <img src="assets/images/vital-signs.svg" alt="vital-signs">
                </span>
                <span>
                    {{ 'SIDENAV.LABELS.Vital_signs' | translate }}
                </span>
            </div>
            <div class="pointer" style="color: #A7A7A7">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </div> -->

        <!-- <div class="nav-item" [ngClass]="{selected: selectedMenu == navItems['calendar']}"
            (click)="onMenuSelect(navItems['calendar']);">
            <div class="item-title">
                <span>
                    <img src="assets/images/calendar.svg" alt="calendar">
                </span>
                <span>
                    {{ 'SIDENAV.LABELS.Calendar' | translate }}
                </span>
            </div>
            <div class="pointer" style="color: #A7A7A7">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </div> -->

        <div class="nav-item" [ngClass]="{selected: selectedMenu == navItems['specialists']}"
            (click)="onMenuSelect(navItems['specialists'])">
            <div class="item-title">
                <span>
                    <img src="assets/images/doctor.svg" alt="specialists">
                </span>
                <span>
                    {{ 'SIDENAV.LABELS.Specialists' | translate }}
                </span>
            </div>
            <div class="pointer" style="color: #A7A7A7">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </div>

        <div class="nav-item" [ngClass]="{selected: selectedMenu == navItems['appointments-list']}"
            (click)="onMenuSelect(navItems['appointments-list'])">
            <div class="item-title">
                <span>
                    <img src="assets/images/calendar.svg" alt="appointments">
                </span>
                <span>
                    {{ 'SIDENAV.LABELS.Appointments' | translate }}
                </span>
            </div>
            <div class="pointer" style="color: #A7A7A7">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </div>

        <div class="nav-item" [ngClass]="{selected: selectedMenu == navItems['calls-history']}"
            (click)="onMenuSelect(navItems['calls-history'])">
            <div class="item-title">
                <span>
                    <img src="assets/images/callsHistory.svg" alt="calls-history">
                </span>
                <span>
                    {{ 'SIDENAV.LABELS.Calls_history' | translate }}
                </span>
            </div>
            <div class="pointer" style="color: #A7A7A7">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </div>

        <div class="nav-item" [ngClass]="{selected: selectedMenu == navItems['questionnaires']}"
            (click)="onMenuSelect(navItems['questionnaires'])">
            <div class="item-title">
                <span>
                    <img src="assets/images/questionnaire.svg" alt="questionnaires">
                </span>
                <span>
                    {{ 'SIDENAV.LABELS.Questionnaires' | translate }}
                </span>
            </div>
            <div class="pointer" style="color: #A7A7A7">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </div>
    </div>

    <div class="nav">
        <div class="nav-item" [ngClass]="{selected: selectedMenu == navItems['subscription-plans']}"
            (click)="onMenuSelect(navItems['subscription-plans'])">
            <div class="item-title">
                <span>
                    <img src="assets/images/subscription-plans.svg" alt="subscription-plans">
                </span>
                <span>
                    {{ 'SIDENAV.LABELS.Subscription_plans' | translate }}
                </span>
            </div>
            <div class="pointer" style="color: #A7A7A7">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </div>

        <div class="nav-item" [ngClass]="{selected: selectedMenu == navItems['buy-credits']}"
            (click)="onMenuSelect(navItems['buy-credits'])">
            <div class="item-title">
                <span>
                    <mat-icon>attach_money</mat-icon>
                </span>
                <span>
                    {{ 'SIDENAV.LABELS.Buy_credits' | translate }}
                </span>
            </div>
            <div class="pointer" style="color: #A7A7A7">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </div>

        <div class="nav-item" [ngClass]="{selected: selectedMenu == navItems['request-medical-observation']}"
            (click)="onMenuSelect(navItems['request-medical-observation'])">
            <div class="item-title">
                <span>
                    <mat-icon>healing</mat-icon>
                </span>
                <span>
                    {{ 'SIDENAV.LABELS.Request_medical_observation' | translate }}
                </span>
            </div>
            <div class="pointer" style="color: #A7A7A7">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </div>
    </div>


    <!-- <div class="nav">
        <div class="nav-item" [ngClass]="{selected: selectedMenu == navItems['devices']}"
            (click)="onMenuSelect(navItems['devices']);">
            <div class="item-title">
                <span>
                    <img src="assets/images/devices.svg" alt="devices">
                </span>
                <span>
                    {{ 'SIDENAV.LABELS.Devices' | translate }}
                </span>
            </div>
            <div class="pointer" style="color: #A7A7A7">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </div>

        <div class="nav-item" [ngClass]="{selected: selectedMenu == navItems['charts']}"
            (click)="onMenuSelect(navItems['charts']);">
            <div class="item-title">
                <span>
                    <img src="assets/images/charts.svg" alt="charts">
                </span>
                <span>
                    {{ 'SIDENAV.LABELS.Charts' | translate }}
                </span>
            </div>
            <div class="pointer" style="color: #A7A7A7">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </div>

    </div> -->

    <!-- <div class="nav">
        <div class="nav-item" [ngClass]="{selected: selectedMenu == navItems['warnings']}"
            (click)="onMenuSelect(navItems['warnings']);">
            <div class="item-title">
                <span>
                    <img src="assets/images/warnings.svg" alt="warnings">
                </span>
                <span>
                    {{ 'SIDENAV.LABELS.Warnings' | translate }}
                </span>
            </div>
            <div class="pointer" style="color: #A7A7A7">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </div>

        <div class="nav-item" [ngClass]="{selected: selectedMenu == navItems['settings']}"
            (click)="onMenuSelect(navItems['settings']);">
            <div class="item-title">
                <span>
                    <img src="assets/images/settings.svg" alt="settings">
                </span>
                <span>
                    {{ 'SIDENAV.LABELS.Settings' | translate }}
                </span>
            </div>
            <div class="pointer" style="color: #A7A7A7">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </div>

    </div> -->

    <div class="last-measured">
        <div class="measure-date">
            {{ vitalsDate | date: 'dd MMMM yyyy' }}
        </div>
        <div class="measure-time" *ngIf="vitalsDate">
            {{ vitalsDate | date: 'HH:mm' }} {{ 'SIDENAV.LABELS.Last_record' | translate }}
        </div>
    </div>

    <div class="vital-signs">

        <div class="vital-sign-box">
            <div class="name">
                {{ 'SIDENAV.LABELS.Blood_pressure' | translate }}
            </div>
            <div class="measurement-value"
                *ngIf="(vitalsData?.ph && vitalsData?.pl) || (lastVitalsData?.ph && lastVitalsData?.pl)">

                {{ (vitalsData?.ph || lastVitalsData?.ph) | number:'2.0-0' }} / {{ (vitalsData?.pl  || lastVitalsData?.pl) | number:'2.0-0' }}
            </div>
            <div class="measurement-text">
                {{ 'SIDENAV.LABELS.Systolic_diаstolic' | translate }}
            </div>
        </div>

        <div class="vital-sign-box">
            <div class="name">
                {{ 'SIDENAV.LABELS.SpO2' | translate }}
            </div>
            <div class="measurement-text" *ngIf="vitalsData?.o2">
                {{ 'SIDENAV.LABELS.In' | translate }} {{ vitalsData?.o2 ? (now | date: 'HH:mm') : (getVitalTime('o2') | date: 'HH:mm') }}
            </div>
            <div class="measurement-value">
                {{ (vitalsData?.o2 || lastVitalsData?.o2) | number:'2.0-0' }}
            </div>
            <div class="measurement-text">
            </div>
        </div>

        <div class="vital-sign-box">
            <div class="name">
                {{ 'SIDENAV.LABELS.Respiration' | translate }}
            </div>
            <div class="measurement-text">
                {{ 'SIDENAV.LABELS.Breaths_per_minute' | translate }}
            </div>

            <div class="measurement-value">
                {{ (vitalsData?.br || lastVitalsData?.br) | number:'2.0-0' }}
            </div>
        </div>

        <div class="vital-sign-box">
            <div class="name">
                {{ 'SIDENAV.LABELS.Heart_rate' | translate }}
            </div>
            <div class="measurement-text">
                {{ 'SIDENAV.LABELS.Beats_per_minute' | translate }}
            </div>
            <div class="measurement-value">
                {{ (vitalsData?.hr || lastVitalsData?.hr) | number:'2.0-0' }}
            </div>

            <!-- <div *ngIf="!vitalsData?.hr && (lastVitalsData?.hr && lastVitalsData?.hr_ts )" style="font-size: 10px">
                {{ lastVitalsData?.hr_ts * 1000 | date: 'HH:mm:ss' }}
            </div> -->
            <!-- <div class="measurement-text">
                <span style="color:#000; font-family: 'SF Pro Display Bold';">
                    82
                </span>
                <span>
                    ectopic beats last hour
                </span>
            </div> -->
        </div>

        <div class="vital-sign-box">
            <div class="name">
                {{ 'SIDENAV.LABELS.Blood_sugar' | translate }}
            </div>
            <div class="measurement-text">
                {{ 'SIDENAV.LABELS.Sugar_in_blood' | translate }}
            </div>

            <div class="measurement-value" *ngIf="vitalsData?.bs || lastVitalsData?.bs">
                {{ (vitalsData?.bs || lastVitalsData?.bs) | number:'2.0-0' }} mmol/L
            </div>
        </div>

        <div class="vital-sign-box">
            <div class="name">
                {{ 'SIDENAV.LABELS.Stress_index' | translate }}
            </div>

            <div class="measurement-value" *ngIf="(vitalsData?.stri && vitalsData.stri > 0) || (lastVitalsData?.stri && lastVitalsData?.stri > 0)">
                {{ (vitalsData?.stri || lastVitalsData?.stri) | number:'2.0-0' }}
            </div>

            <div class="measurement-value" *ngIf="(vitalsData?.stri && vitalsData.stri < 0) || (lastVitalsData?.stri && lastVitalsData?.stri < 0)">
                --
            </div>
        </div>

        <div class="vital-sign-box">
            <div class="name">
                {{ 'SIDENAV.LABELS.Position' | translate }}
            </div>
            
            <div style="font-size: 16px;"  *ngIf="vitalsData?.ac">
                <div class="flex-col align-items-center gap05">
                    <span>{{ getPositionLabel(vitalsData.ac) }}</span>
                    <img [src]="getPostureImageSrc(vitalsData.ac)" alt="posture" *ngIf="getPostureImageSrc(vitalsData.ac)">
                </div>
            </div>

            <div *ngIf="!vitalsData?.ac && lastVitalsData?.ac">
                <div class="flex-col align-items-center gap05">
                    <span>{{ getPositionLabel(lastVitalsData.ac) }}</span>
                    <img [src]="getPostureImageSrc(lastVitalsData.ac)" alt="posture">
                </div>
            </div>
            
        </div>

        <div class="vital-sign-box">
            <div class="name">
                {{ 'SIDENAV.LABELS.Steps' | translate }}
            </div>
            
            <div class="measurement-value">
                {{ (vitalsData?.sc || lastVitalsData?.sc) | number:'2.0-0' }}
            </div>
        </div>

    </div>

    {{ 'SIDENAV.LABELS.Version' | translate}} {{ version }}

</div>