export const environment = {
    production: false,
    defaultLanguage: 'en',
    // apiUrl: 'api.myki.cpcardio.com',
    apiUrl: 'beta.cpcardio.com/api/v2',
    apiVer2URL: 'beta.cpcardio.com/api/v2',
    bookingAPI: 'd.infn.dev/myki-cardio-calendar-backend',
    imageDir: 'https://beta.cpcardio.com/api/',
    protocol: 'https://',
    deviceApiUrl: 'http://borov.net:8080/',
    socketIO: 'https://beta.cpcardio.com:8005',
    videoCallApi: 'https://wb.daskal.eu/api/v2/',
    videoCallWs: 'wss://wb.daskal.eu/r',
    paypal: {
        clientId: 'AQi-l5BmFq8Z51egjPdiU4WLqI_zecVjSX9X1GXmy5jX9LWY1prlfGq4fXunq4lhavXedWno4umSFaoI'
    },
    revolut: {
        api_key: 'sk_o2sBAdK1H7wYlcRtevi55SNRMBCLYMBUgCqGDDnNSiBRD4xHtFnDOo6oAwtwvLHb',
        environment: 'sandbox',
        currency: 'USD'
    },
    version: '1.79'
};