import { Injectable } from '@angular/core';
import { endOfMonth, startOfMonth } from 'date-fns';
import endOfYear from 'date-fns/endOfYear';
import startOfYear from 'date-fns/startOfYear';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

enum VitalDataType {
  heartrate = 1,
  respiration = 2,
  bloodPressure = 3,
  ctBloodPresDia = 4,
  ctSos = 5,
  ctWarnings = 6,
  ctBeatNormal = 10,
  ctSingleSVES = 11,
  ctSingleVES = 12,
  ctMultipleSVES = 13,
  ctMultipleVES = 14,
  ctPosture = 20,

  //only for debug
  ctAmp1 = 21,
  ctAmp2 = 22,
  ctTop1 = 23,
  ctTop2 = 24,
  ctSoundInterval = 25,
  ctNoise = 26,

  ctBloodPresSysAlarm = -1,
  ctHeartRateAlarm = -2,
  bloodPresSysAlarm = 255,
  heartRateAlarm = 254,
  ctRespirationRateAlarm = 253,
  ctECGAlarm = 252,
}

export type Measurements = {
  MeasureParamID: number | undefined | null;
  MeasureParamAbbr: string | undefined | null;
  MeasureParamValue: number | string | undefined | null;
  MeasureParamTimeStamp: number | undefined | null;
  MeasureParamDevice: string | undefined | null;
  MeasureParamAlarm?: number | string | undefined | null;
}

@Injectable({
  providedIn: 'root'
})
export class ChartsService extends ApiService {
  private deviceApi = this.apiUrl + 'user-device/';
  private availableDays = this.deviceApi + 'available-days';
  public hourDataSource = new BehaviorSubject(undefined);
  public hourData$ = this.hourDataSource.asObservable();

  static VitalsDataType = VitalDataType;
  static POSTURE = [
    "UNDEFINED", "STANDING", "STANDING", "WALKING", "RUNNING", "UNDEFINED", "UNDEFINED", "LAY SUPINE", "LAY LEFT SIDE",
    "LAY RIGHT SIDE", "LAY PRONE", "LEAN BACK"
  ];

  getAvailableMonthData(date: any, userID: any = undefined) {
    const start = startOfMonth(date).getTime() / 1000;
    const end = endOfMonth(date).getTime() / 1000;
    let url = (userID) ? this.availableDays + '/' + userID : this.availableDays;

    return this.http.post(url, { start, end });
  }

  getAvailableYearData(year: any, userID: any = undefined) {
    let date = new Date(year, 0, 1);
    const start = startOfYear(date).getTime() / 1000;
    const end = endOfYear(date).getTime() / 1000;
    let url = (userID) ? this.availableDays + '/' + userID : this.availableDays;

    return this.http.post(url, { start, end });
  }

  setSelectedHour(hourData: any) {
    this.hourDataSource.next(hourData);
  }
}
