import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { VitalAlerts, VitalTypes } from '../utils/types';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DeviceApiService extends ApiService {

    private deviceApi = this.apiUrl + 'user-device/';
    private deviceList = this.deviceApi + 'device-list';
    private deviceDirList = this.apiUrl + 'user-device/list-device-dir';
    private deviceFilesList = this.apiUrl + 'user-device/list-device-files';
    private downloadDeviceFileUrl = this.apiUrl + 'user-device/download-device-file';
    private dataByDay = this.deviceApi + 'data-by-day';
    private markDataUrl = this.deviceApi + 'mark-data';
    private userDataByDay = this.deviceApi + 'user-data-by-day';
    private availableDays = this.deviceApi + 'available-days';
    private availableMarkDays = this.deviceApi + 'available-mark-days';
    private dataBySec = this.deviceApi + 'data-by-sec';
    private dataByMin = this.deviceApi + 'data-by-min';
    private signalData = this.deviceApi + 'signal-data';
    private userDevices = this.deviceApi + 'user-devices';
    //new api calls
    private api2Url = environment.protocol + environment.apiVer2URL + '/';
    private visitUrl = this.api2Url + 'visit';
    private vitalsUrl = this.api2Url + 'vital';
    private reqId = "Sthve^UVT34HybhIL/AXra'Xrtpcd4SwDET&Ulscsp.cmdg";

    // private dataByHour = this.apiUrl + '?query=availhours';
    //private dataByMin = 'http://borov.net:8080/datainfo?query=availminutes';

    public getDeviceList(): Observable<any> {
        return this.http.get(this.deviceList);
    }

    public getDeviceDirList(): Observable<any> {
        return this.http.get(this.deviceDirList);
    }

    public getDeviceFileList(device: string): Observable<any> {
        return this.http.get(this.deviceFilesList + '/' + device);
    }

    public downloadDeviceFile(device: string, file: string): Observable<any> {
        return this.http.get(this.downloadDeviceFileUrl + '/' + device + '/' + file);
    }

    public getDataByDay(data: any): Observable<any> {
        return this.http.post(this.dataByDay, data);
    }

    public getMarkData(data): Observable<any> {
        return this.http.post(this.markDataUrl, data);
    }

    public getDataBySec(data: any): Observable<any> {
        return this.http.post(this.dataBySec, data);
    }

    public getUserDataByDay(): Observable<any> {
        return this.http.post(this.userDataByDay, {});
    }

    public getAvailableDays(userID, data): Observable<any> {
        var url = (userID) ? this.availableDays + '/' + userID : this.availableDays;
        return this.http.post(url, data);
    }

    public getAvailableMarkDays(userID, data): Observable<any> {
        var url = (userID) ? this.availableMarkDays + '/' + userID : this.availableMarkDays;
        return this.http.post(url, data);
    }

    public getPaymentDataByDay(data: any): Observable<any> {
        return this.http.post(this.deviceApi + 'payment-data-by-day', data);
    }

    public getSignalData(visitId: any, data: any): Observable<any> {
        let url = this.api2Url + `visit/${visitId}/signal-data?`;
        return this.http.get(url + data, {});
    }

    public getUserDevices(userID: number): Observable<any> {
        return this.http.get(this.userDevices + '/' + userID);
    }

    // public getDataByHour(data: any): Observable<any> {
    //     return this.http.get(this.dataByHour, {params: data});
    // }


    public getDataByMin(data: any): Observable<any> {
        //return this.http.post(this.dataByMin, {params: data});
        return this.http.post(this.dataByMin, data);
    }


    // New API calls
    public getAvailableDays2(visitId: any, from: any, to: any): Observable<any> {
        let url = this.visitUrl + `/${visitId}/available-days` + `?from=${from}&to=${to}`;
        return this.http.get(url);
    }

    public getVitals(visitId: any, from: any, to: any, vitals: string[] = []): Observable<any> {
        const req_id = this.reqId;
        if (vitals.length == 0) {
            vitals = [
                VitalTypes.RESPIRATION,
                VitalTypes.SATURATION,
                VitalTypes.HEART_RATE,
                VitalTypes.TEMPERATURE,
                VitalTypes.BLOOD_PRESSURE_SYSTOLE,
                VitalTypes.BLOOD_PRESSURE_DIASTOLE,
                VitalTypes.POSTURE,
                VitalAlerts.RESPIRATION,
                VitalAlerts.SATURATION,
                VitalAlerts.HEART_RATE,
                VitalAlerts.TEMPERATURE,
                VitalAlerts.BLOOD_PRESSURE
            ];
        }
        let body = {
            from,
            to,
            vi: visitId,
            req_id,
            vps: vitals
        }

        return this.http.post(this.vitalsUrl, body);
    }

    public getVitalsThreshold(visitId: any) {
        let url = this.visitUrl + `/${visitId}/vital-params-treshold`;
        return this.http.get(url);
    }

    public getManuallyInputVitals(visitId: any, from: any, to: any, params: string[] = []) {
        let paramsString = params.join(',');
        let url = this.api2Url + `visit/${visitId}/list-measure-param?from=${from}&to=${to}&params=${paramsString}&paramsType=abbr`;
        
        return this.http.get(url);
    }
}
